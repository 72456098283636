<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 20:37:34
-->
<template>
  <a-result status="404" title="404" sub-title="404！没有找到资源">
    <template #extra>
      <a-button type="primary" @click="toHome">返回主页</a-button>
    </template>
  </a-result>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const router = useRouter();
    const toHome = router.replace({ name: "User" });
    return { toHome };
  },
});
</script>
